<template>
  <div style="margin: 0 auto; max-width: 1200px">
    <div>
      <div style="padding: 4px 4px 0px; border-radius: 8px; box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; margin: 12px 4px 4px; background: rgba(24, 34, 54, 0.5); cursor: pointer">
        <TagEditor :tagLoad="tags" :lowerCase="true" :search="true" :colors="['var(--color-select)', 'var(--color-gradient)']" :newValue="newValue" startChars="#" />
      </div>
      <div style="text-align: right">
        <button class="buttons" v-if="primero?.id === primerRegistro?.id" @click="queryFirestore(false, true)">BUSCAR NUEVOS <ThemifyIcon icon="angle-double-up" /></button>
        <button class="buttons" v-if="primero?.id !== primerRegistro?.id" @click="queryFirestore(false, true)">PRIMERO <ThemifyIcon icon="angle-double-up" /></button>
        <button class="buttons" v-if="primero?.id !== primerRegistro?.id" @click="queryFirestore(false)">SUBIR <ThemifyIcon icon="angle-up" /></button>
      </div>
      <GrillaCard
        :percent="percent(cruci.id)"
        v-for="cruci of data"
        :key="cruci.id"
        :cruci="cruci"
        style="margin: 12px !important"
        :action="{
          icon: 'control-play',
          click: () => {
            setLoading(true);
            $router.push('/-' + cruci.id);
          },
        }"
      />
      <div style="text-align: right">
        <button v-if="data.length > 0 && ultimo?.id !== ultimoRegistro?.id" class="buttons" @click="queryFirestore(true, true)">ULTIMO&nbsp;<ThemifyIcon icon="angle-double-down" /></button>
        <button v-if="data.length > 0 && ultimo?.id !== ultimoRegistro?.id" class="buttons" @click="queryFirestore(true)">BAJAR&nbsp;<ThemifyIcon icon="angle-down" /></button>
        <span style="margin-right: 16px" v-if="data.length === 0 || ultimo?.id === ultimoRegistro?.id">No hay mas crucigramas</span>
      </div>
    </div>
  </div>
</template>
<script>
import { getFirestore, collection, query, where, orderBy, onSnapshot, limit, startAfter } from 'firebase/firestore';
import TagEditor from '../components/TagEditor.vue';
import GrillaCard from '../components/GrillaCard.vue';
import { mapGetters, mapState } from 'vuex';
import ThemifyIcon from 'vue-themify-icons';
let lastUpdate = 0;
let firstRetag = true;

export default {
  name: 'List',
  components: {
    TagEditor,
    GrillaCard,
    ThemifyIcon,
  },
  data() {
    return {
      pageSize: 6,
      primero: undefined,
      primerRegistro: undefined,
      ultimoRegistro: undefined,
      ultimo: undefined,
      data: [],
      users: {},
      tags: [],
      expandedId: null,
    };
  },
  computed: {
    ...mapGetters(['uid']),
  },
  watch: {
    tags: {
      handler: function (value) {
        this.setLocal('tags', JSON.stringify(value));
      },
      deep: true,
    },
    '$route.params': {
      async handler() {
        const lasthash = this.getData('lasthash');
        let hash = window.location.hash;
        this.setData('lasthash', hash);
        if (lasthash !== hash) {
          this.setData('data');
          this.data = [];
          this.ultimo = undefined;
          this.primero = undefined;
          this.ultimoRegistro = undefined;
          this.primerRegistro = undefined;
          this.queryFirestore();
        } else {
          let dat = this.getData('data');
          if (dat) {
            this.data = dat;
            this.primero = this.getData('primero');
            this.ultimo = this.getData('ultimo');
            this.primerRegistro = this.getData('primerRegistro');
            this.ultimoRegistro = this.getData('ultimoRegistro');
          }
          this.setLoading(false, 2);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    let s = decodeURI(this.$route.hash).toLowerCase();
    s = s.match(/(#|@)[\w+ñ]+/gi);
    this.tags = s ? s : [];
    //console.log('tags', this.tags);
    if (this.$route.fullPath.startsWith('/-')) {
      this.expandedId = this.$route.fullPath.substring(2);
      this.expandedId = null;
    }
    if (this.tags.length === 0 && firstRetag) {
      this.retag();
      firstRetag = false;
    }
  },
  methods: {
    retag() {
      const s = this.getLocal('tags');
      const tags = s ? s.match(/(#|@)[\w+ñ]+/gi) : [];
      // console.log('tags', tags);
      this.newValue(tags, 0);
    },
    percent(id) {
      const s = this.getLocal('-' + id + 'completado');
      return s === null ? 0 : parseInt(s);
    },
    async queryFirestore(loadMore, edges) {
      if (loadMore === undefined) {
        let dat = this.getData('data');
        if (dat) {
          this.data = dat;
          this.primero = this.getData('primero');
          this.ultimo = this.getData('ultimo');
          this.primerRegistro = this.getData('primerRegistro');
          this.ultimoRegistro = this.getData('ultimoRegistro');
          return;
        }
      }

      this.setLoading(true);
      await this.delay(100);

      let s = decodeURI(this.$route.hash).toLocaleLowerCase();
      //console.log('s', s);
      s = s.match(/(#|@)[\w+ñ]+/gi);
      this.tags = s ? s : [];
      //console.log('tags', this.tags);

      this.newValue(this.tags);
      const db = getFirestore();
      this.pageSize = Math.floor((window.innerHeight - 180) / document.body.style.zoom / 84);
      //console.log(this.pageSize);

      let baseQuestion = [collection(db, 'social'), limit(this.pageSize)];

      if (this.tags.includes('#mine') && this.uid) {
        baseQuestion.push(where('uid', '==', this.uid));
      } else {
        baseQuestion.push(where('public', '==', true));
      }
      let tagss = this.tags.filter((t) => t !== '#mine');
      if (tagss.length > 0) {
        baseQuestion.push(where('desc', 'array-contains-any', tagss));
      }

      //console.log('baseQuestion', baseQuestion);
      let audio = this.getLocal('audio');
      audio = audio ? parseInt(audio) : 2;
      if (loadMore === true || loadMore === false) {
        this.playClick(audio);
        if (edges === true) {
          if (loadMore === true) {
            //ultimo registro
            baseQuestion.push(orderBy('fecha', 'asc'));
          } else {
            //primer registro
            baseQuestion.push(orderBy('fecha', 'desc'));
          }
        } else {
          if (!loadMore) {
            baseQuestion.push(orderBy('fecha', 'asc'), startAfter(this.primero));
          } else {
            baseQuestion.push(orderBy('fecha', 'desc'), startAfter(this.ultimo));
          }
        }
      } else {
        baseQuestion.push(orderBy('fecha', 'desc'));
      }

      let q = await query(...baseQuestion);

      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        let uids = new Set();
        const size = querySnapshot.docs.length;
        if (size > 0) {
          //console.log('size', size);
          this.data = [];

          if (edges === true) {
            if (loadMore === true) {
              //ultimo
              this.ultimo = querySnapshot.docs[0];
              this.primero = querySnapshot.docs[querySnapshot.docs.length - 1];
            } else {
              //primero
              this.primero = querySnapshot.docs[0];
              this.ultimo = querySnapshot.docs[querySnapshot.docs.length - 1];
            }
          } else {
            if (loadMore !== undefined && loadMore === false) {
              this.ultimo = querySnapshot.docs[0];
              this.primero = querySnapshot.docs[querySnapshot.docs.length - 1];
            } else {
              this.primero = querySnapshot.docs[0];
              this.ultimo = querySnapshot.docs[querySnapshot.docs.length - 1];
            }
          }

          //console.log('primero', this.primero?.data().fecha);
          //console.log('ultimo', this.ultimo?.data().fecha);
          querySnapshot.docs.forEach((doc) => {
            const data = doc.data();
            // console.log(data.desc, data.fecha.toDate());
            uids.add(data.uid);
            if (this.data.some((e) => e.id === doc.id)) {
              this.data = this.data.map((e) => {
                if (e.id === doc.id) {
                  return { ...data, id: doc.id };
                } else {
                  return e;
                }
              });
            } else {
              this.data.push({ ...data, id: doc.id });
              this.data.sort((a, b) => b.fecha.toMillis() - a.fecha.toMillis());
            }
            const data2 = { storeTime: Date.now(), plays: data.plays, public: data.public, stars: data.stars, times: data.times, timesCount: data.timesCount, votos: data.votos, fecha: data.fecha };
            localStorage.setItem(doc.id + 'cruciData', JSON.stringify(data2));
            this.cruciData(doc.id, data2);
          });
          unsubscribe();
          this.setData('data', this.data);
          if (edges) {
            if (loadMore === false) {
              this.setData('primerRegistro', this.primero);
              this.primerRegistro = this.primero;
            } else {
              this.setData('ultimoRegistro', this.ultimo);
              this.ultimoRegistro = this.ultimo;
            }
          } else {
            if (loadMore !== true && loadMore !== false) {
              this.setData('primerRegistro', this.primero);
              this.primerRegistro = this.primero;
            }
          }

          if (size < this.pageSize) {
            if (loadMore === true || loadMore === undefined) {
              this.setData('ultimoRegistro', this.ultimo);
              this.ultimoRegistro = this.ultimo;
            } else if (loadMore === false) {
              this.setData('primerRegistro', this.primero);
              this.primerRegistro = this.primero;
            }
          }

          this.setData('primero', this.primero);
          this.setData('ultimo', this.ultimo);
        } else {
          if (edges) {
            if (loadMore === false) {
              this.setData('primero', this.primero);
              this.primerRegistro = this.primero;
              this.setData('primerRegistro', this.primerRegistro);
            } else {
              this.setData('ultimo', this.ultimo);
              this.ultimoRegistro = this.ultimo;
              this.setData('ultimoRegistro', this.ultimoRegistro);
            }
          } else {
            if (loadMore !== undefined && loadMore === false) {
              this.primerRegistro = this.primero;
            } else {
              if (this.data.length > 0) {
                this.ultimoRegistro = this.data[this.data.length - 1];
              }
            }
          }

          //this.playFail(audio);
        }
        this.setLoading(false, 3);
      });
    },
    newValue(valueArray, DELAY = 300) {
      if (valueArray !== undefined && valueArray !== null && JSON.stringify(valueArray) !== JSON.stringify(this.tags)) {
        //console.log(JSON.stringify(this.tags), '->', JSON.stringify(valueArray));
        //console.log('TAGS >>>> ' + JSON.stringify(valueArray));
        let value = valueArray.join('');
        const now = Date.now();
        lastUpdate = now;
        setTimeout(() => {
          if (now === lastUpdate) {
            this.setLocal('tags', valueArray);
            this.tags = valueArray;
            if (value !== this.$route.hash) {
              //console.log(value, this.$route.hash);
              this.$router.push({ hash: value }).catch((err) => {});
            }
          }
        }, DELAY);
      }
    },
  },
};
</script>
<style scoped>
.buttons {
  padding: 4px;
  margin: 0 16px 4px 4px;
}
.buttons i {
  font-size: 20px;
  position: relative;
  top: 4px;
}
</style>
