var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin":"0 auto","max-width":"1200px"}},[_c('div',[_c('div',{staticStyle:{"padding":"4px 4px 0px","border-radius":"8px","box-shadow":"rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px","margin":"12px 4px 4px","background":"rgba(24, 34, 54, 0.5)","cursor":"pointer"}},[_c('TagEditor',{attrs:{"tagLoad":_vm.tags,"lowerCase":true,"search":true,"colors":['var(--color-select)', 'var(--color-gradient)'],"newValue":_vm.newValue,"startChars":"#"}})],1),_c('div',{staticStyle:{"text-align":"right"}},[(_vm.primero?.id === _vm.primerRegistro?.id)?_c('button',{staticClass:"buttons",on:{"click":function($event){return _vm.queryFirestore(false, true)}}},[_vm._v("BUSCAR NUEVOS "),_c('ThemifyIcon',{attrs:{"icon":"angle-double-up"}})],1):_vm._e(),(_vm.primero?.id !== _vm.primerRegistro?.id)?_c('button',{staticClass:"buttons",on:{"click":function($event){return _vm.queryFirestore(false, true)}}},[_vm._v("PRIMERO "),_c('ThemifyIcon',{attrs:{"icon":"angle-double-up"}})],1):_vm._e(),(_vm.primero?.id !== _vm.primerRegistro?.id)?_c('button',{staticClass:"buttons",on:{"click":function($event){return _vm.queryFirestore(false)}}},[_vm._v("SUBIR "),_c('ThemifyIcon',{attrs:{"icon":"angle-up"}})],1):_vm._e()]),_vm._l((_vm.data),function(cruci){return _c('GrillaCard',{key:cruci.id,staticStyle:{"margin":"12px !important"},attrs:{"percent":_vm.percent(cruci.id),"cruci":cruci,"action":{
        icon: 'control-play',
        click: () => {
          _vm.setLoading(true);
          _vm.$router.push('/-' + cruci.id);
        },
      }}})}),_c('div',{staticStyle:{"text-align":"right"}},[(_vm.data.length > 0 && _vm.ultimo?.id !== _vm.ultimoRegistro?.id)?_c('button',{staticClass:"buttons",on:{"click":function($event){return _vm.queryFirestore(true, true)}}},[_vm._v("ULTIMO "),_c('ThemifyIcon',{attrs:{"icon":"angle-double-down"}})],1):_vm._e(),(_vm.data.length > 0 && _vm.ultimo?.id !== _vm.ultimoRegistro?.id)?_c('button',{staticClass:"buttons",on:{"click":function($event){return _vm.queryFirestore(true)}}},[_vm._v("BAJAR "),_c('ThemifyIcon',{attrs:{"icon":"angle-down"}})],1):_vm._e(),(_vm.data.length === 0 || _vm.ultimo?.id === _vm.ultimoRegistro?.id)?_c('span',{staticStyle:{"margin-right":"16px"}},[_vm._v("No hay mas crucigramas")]):_vm._e()])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }